"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopUnitUserInfoToJSON = exports.ShopUnitUserInfoFromJSONTyped = exports.ShopUnitUserInfoFromJSON = exports.instanceOfShopUnitUserInfo = void 0;
var runtime_1 = require("../runtime");
var ShopUserType_1 = require("./ShopUserType");
/**
 * Check if a given object implements the ShopUnitUserInfo interface.
 */
function instanceOfShopUnitUserInfo(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfShopUnitUserInfo = instanceOfShopUnitUserInfo;
function ShopUnitUserInfoFromJSON(json) {
    return ShopUnitUserInfoFromJSONTyped(json, false);
}
exports.ShopUnitUserInfoFromJSON = ShopUnitUserInfoFromJSON;
function ShopUnitUserInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'shopUnitId': !(0, runtime_1.exists)(json, 'shopUnitId') ? undefined : json['shopUnitId'],
        'shopUnitName': !(0, runtime_1.exists)(json, 'shopUnitName') ? undefined : json['shopUnitName'],
        'role': !(0, runtime_1.exists)(json, 'role') ? undefined : (0, ShopUserType_1.ShopUserTypeFromJSON)(json['role']),
        'budget': !(0, runtime_1.exists)(json, 'budget') ? undefined : json['budget'],
        'isAttestent': !(0, runtime_1.exists)(json, 'isAttestent') ? undefined : json['isAttestent'],
        'manageBudget': !(0, runtime_1.exists)(json, 'manageBudget') ? undefined : json['manageBudget'],
        'requiresAttestation': !(0, runtime_1.exists)(json, 'requiresAttestation') ? undefined : json['requiresAttestation'],
    };
}
exports.ShopUnitUserInfoFromJSONTyped = ShopUnitUserInfoFromJSONTyped;
function ShopUnitUserInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'shopUnitId': value.shopUnitId,
        'shopUnitName': value.shopUnitName,
        'role': (0, ShopUserType_1.ShopUserTypeToJSON)(value.role),
        'budget': value.budget,
        'isAttestent': value.isAttestent,
        'manageBudget': value.manageBudget,
        'requiresAttestation': value.requiresAttestation,
    };
}
exports.ShopUnitUserInfoToJSON = ShopUnitUserInfoToJSON;
