// import { faAngleDown, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faAngleDown, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import Select, {
    ClearIndicatorProps,
    DropdownIndicatorProps,
    MultiValueRemoveProps,
    StylesConfig,
    components,
} from 'react-select';
import { Props as SelectProps } from 'react-select/dist/declarations/src/Select';
import { MenuListProps } from 'react-select/dist/declarations/src/components/Menu';
import styled, { ThemeColors, ThemeWebColors, useTheme } from 'styled-components/macro';
import LinkStyle from './LinkStyle';
import Text from './Text';

type ThemeColorsAll = keyof ThemeColors | keyof ThemeWebColors;

export type Option<T = unknown> = { value: T; label: string; color?: ThemeColorsAll };
export type Group<P = Option> = { label?: string; options: P[]; color?: ThemeColorsAll };
type IsMulti = boolean;

export function isGroup(optionOrGroup: Group | Option): optionOrGroup is Group {
    return 'options' in optionOrGroup;
}

const SelectAllMenuList = (props: MenuListProps<Option, IsMulti, Group>) => {
    const selectedValues = props.getValue();
    const allValues = props.options.flatMap((o) => (isGroup(o) ? o.options : o));
    const diff = allValues.filter((v) => !selectedValues.some((sv) => sv.value === v.value));

    const selectAll = selectedValues && diff.length;

    return (
        <>
            <components.MenuList {...props}>{props.children}</components.MenuList>
            <div className="d-flex p-1">
                <LinkStyle
                    onClick={() => {
                        if (selectAll) {
                            props.setValue([...selectedValues, ...diff], 'select-option');
                        } else {
                            props.setValue([], 'deselect-option');
                        }
                    }}
                    style={{ cursor: 'pointer' }}
                    className="text-underline text-blue cursor-pointer ml-auto mr-2"
                >
                    {selectAll ? 'Select All' : 'Select None'}
                </LinkStyle>
            </div>
        </>
    );
};

const NwgDropdownIndicator = (props: DropdownIndicatorProps<Option>) => (
    <components.DropdownIndicator {...props}>
        <IndicatorIcon fontSize={20} icon={faAngleDown} />
    </components.DropdownIndicator>
);

const NwgClearIndicator = (props: ClearIndicatorProps<Option>) => (
    <components.ClearIndicator {...props}>
        <IndicatorIcon fontSize={18} icon={faTimes} />
    </components.ClearIndicator>
);

const NwgMultiValueRemove = (props: MultiValueRemoveProps<Option>) => {
    return (
        <components.MultiValueRemove {...props}>
            <IndicatorIcon fontSize={12} icon={faTimes} />
            {/* <EmojiIcon label="Emoji" primaryColor={colourOptions[2].color} /> */}
        </components.MultiValueRemove>
    );
};

type Props = Partial<SelectProps<Option, IsMulti, Group<Option>>> & {
    /** The label visible above the select field. */
    label?: string | undefined | null;
    /** Adds a button to select all options in one click. */
    selectAll?: boolean;
    /** Turns the label into a lighter shade of gray. */
    lightLabel?: boolean;
    /** Decreases the font size of the label. */
    smallLabel?: boolean;
};

const ReactSelect = ({
    name,
    label = null,
    selectAll = false,
    lightLabel = false,
    smallLabel = false,
    ...props
}: Props) => {
    const theme = useTheme();

    const selectStyles: StylesConfig<Option, IsMulti, Group<Option>> = {
        input: (provided) => ({
            ...provided,
        }),
        control: (provided) => ({
            ...provided,
            border: `1px solid ${theme.colors.borderLight} `,
            boxShadow: 'none',

            ':hover': {
                borderColor: theme.colors.border,
            },
        }),
        menuList: (provided) => ({
            ...provided,
            padding: 0,
        }),
        groupHeading: (provided, state) => ({
            ...provided,
            color: state.data.color
                ? { ...theme.colors, ...theme.webColors }[state.data.color]
                : theme.colors.textLight,
            borderLeft: state.data.color
                ? `3px solid ${{ ...theme.colors, ...theme.webColors }[state.data.color]}`
                : provided.borderLeft,
            margin: 0,
            height: '2em',
        }),
        multiValue: (provided, state) => ({
            ...provided,
            backgroundColor: state.data.color
                ? transparentize(0.65, { ...theme.colors, ...theme.webColors }[state.data.color])
                : provided.backgroundColor,
        }),
        option: (provided, state) => ({
            ...provided,
            borderLeft: state.data.color
                ? `3px solid ${{ ...theme.colors, ...theme.webColors }[state.data.color]}`
                : provided.borderLeft,
        }),
    };

    // ! Actively typing this as any to resolve problems with build typecheck. Types work but are not correct.
    const modifiedComponents: any = {
        DropdownIndicator: NwgDropdownIndicator,
        ClearIndicator: NwgClearIndicator,
        MultiValueRemove: NwgMultiValueRemove,
        MenuList: selectAll ? SelectAllMenuList : components.MenuList,
    };

    return (
        <>
            {label && (
                <Label
                    htmlFor={props.id}
                    className={!lightLabel ? 'f3-700' : undefined}
                    lightLabel={lightLabel}
                    smallLabel={smallLabel}
                >
                    {label}
                    {props.required && (
                        <Text small className="d-inline" color="textFaded">
                            {' *'}
                        </Text>
                    )}
                </Label>
            )}
            <div>
                <Select
                    {...props}
                    // label={label}
                    styles={selectStyles}
                    theme={(provided) => ({
                        ...provided,
                        borderRadius: 2,
                        colors: {
                            ...provided.colors,
                            primary: transparentize(0.2, theme.colors.accent), // Menulist option selected
                            // primary75: transparentize(0.4, theme.colors.accent),
                            primary50: transparentize(0.6, theme.colors.accent), // Menulist option active
                            primary25: transparentize(0.8, theme.colors.accent), // Menulist option hovered
                            // neutral50: theme.colors.error, // placeholder choose
                            // neutral80: theme.colors.error, // placeholder selected + multiselect selected label
                        },
                    })}
                    noOptionsMessage={() => 'All options selected'}
                    components={modifiedComponents}
                />
            </div>
        </>
    );
};

export default ReactSelect;

export const Label = styled.label<{
    lightLabel?: boolean;
    smallLabel?: boolean;
}>`
    width: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: ${({ smallLabel }) => (smallLabel ? 12 : 15)}px;
    letter-spacing: 0.5px;
    color: ${({ lightLabel, theme }) => (lightLabel ? theme.colors.textLight : theme.colors.text)};
    transition: all 0.3s ease-in-out;
    margin-bottom: 0;
`;

const IndicatorIcon = styled(FontAwesomeIcon)`
    width: 20px;
    cursor: pointer;
`;
