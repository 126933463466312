"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserInfoToJSON = exports.UserInfoFromJSONTyped = exports.UserInfoFromJSON = exports.instanceOfUserInfo = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the UserInfo interface.
 */
function instanceOfUserInfo(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfUserInfo = instanceOfUserInfo;
function UserInfoFromJSON(json) {
    return UserInfoFromJSONTyped(json, false);
}
exports.UserInfoFromJSON = UserInfoFromJSON;
function UserInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
        'firstName': !(0, runtime_1.exists)(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !(0, runtime_1.exists)(json, 'lastName') ? undefined : json['lastName'],
        'fullName': !(0, runtime_1.exists)(json, 'fullName') ? undefined : json['fullName'],
        'customerCode': !(0, runtime_1.exists)(json, 'customerCode') ? undefined : json['customerCode'],
        'customerName': !(0, runtime_1.exists)(json, 'customerName') ? undefined : json['customerName'],
        'profileImageUrl': !(0, runtime_1.exists)(json, 'profileImageUrl') ? undefined : json['profileImageUrl'],
    };
}
exports.UserInfoFromJSONTyped = UserInfoFromJSONTyped;
function UserInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'fullName': value.fullName,
        'customerCode': value.customerCode,
        'customerName': value.customerName,
        'profileImageUrl': value.profileImageUrl,
    };
}
exports.UserInfoToJSON = UserInfoToJSON;
