import { faSignOutAlt, faUser, faUserAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderIconButton from 'components/ui/HeaderIconButton';
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import styled from 'styled-components';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import authService from './AuthorizeService';
import useAppSelector from 'hooks/useAppSelector';
import { ShopUnitUserInfo } from 'microshop-api';
import { setShopUnit } from 'services/api';

const LoginMenu = ({
    loginLabel,
    logoutLabel,
    className,
}: {
    loginLabel: string;
    logoutLabel: string;
    className: string;
}) => {
    const [userInfo, setUserInfo] = useState({
        isAuthenticated: true,
        userName: null,
        fullName: null,
    });

    const shopUnits = useAppSelector(({ user }) => user.userIdentity?.shopUnits);

    const [dropDown, setDropDownOpen] = useState<boolean>(false);

    useEffect(() => {
        const populateState = async () => {
            const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
            setUserInfo({
                isAuthenticated,
                userName: user && user.name,
                fullName: user && user.fullname,
            });
        };
        const subscription = authService.subscribe(() => populateState());
        populateState();
        return () => {
            authService.unsubscribe(subscription);
        };
    }, []);

    const anonymousView = (loginPath: string) => {
        return (
            <Link className="m-0 p-0 nav-link" to={loginPath}>
                <Tooltip placement="top">
                    <div className="mt-2 ms-2">
                        <FontAwesomeIcon icon={faUser} />
                    </div>
                    <span>
                        <small>{loginLabel}</small>
                    </span>
                </Tooltip>
            </Link>
        );
    };

    const swapShopUnit = (shopUnit: ShopUnitUserInfo) => {
        const shopUnitId = shopUnit.shopUnitId?.toString() || '';
        localStorage.setItem('shopUnitId_' + window.location.hostname, shopUnitId);
        setShopUnit(shopUnitId);
        window.location.replace(window.location.origin + '/');
    };

    const authenticatedView = (
        userName: string | null,
        fullName: string | null,
        profilePath: string,
        logoutPath: { pathname: string; state: { local: boolean } },
    ) => {
        return (
            <>
                <Dropdown direction="right" toggle={() => setDropDownOpen(!dropDown)} isOpen={dropDown}>
                    <ButtonWrapper>
                        <DropdownToggle className={className} nav color="dark">
                            <Icon small icon={faUser} className={`ms-2 ms-lg-3`} aria-label="faUser" />
                        </DropdownToggle>
                        <DropdownMenu color="dark">
                            <DropdownItem>
                                <Link className="m-0 p-0" to={profilePath}>
                                    <div className="d-flex flex-row">
                                        <div className="p-1 mt-1 me-2">
                                            <FontAwesomeIcon icon={faUserAlt} />
                                        </div>
                                        <div className="p-11 font-weight-lighter">
                                            <div>{fullName}</div>
                                            <div>
                                                <small>{userName}</small>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </DropdownItem>
                            {shopUnits &&
                                shopUnits.length > 0 &&
                                (shopUnits.length === 1 ? (
                                    <DropdownItem>
                                        <div>{shopUnits[0].shopUnitName}</div>
                                    </DropdownItem>
                                ) : (
                                    shopUnits.map((u) => (
                                        <DropdownItem onClick={() => swapShopUnit(u)} key={u.shopUnitId}>
                                            <div>{u.shopUnitName}</div>
                                        </DropdownItem>
                                    ))
                                ))}
                            <DropdownItem>
                                <Link className="m-0 p-0" to={logoutPath.pathname} state={logoutPath.state}>
                                    <div className="d-flex flex-row">
                                        <div className="p-1 me-2">
                                            <FontAwesomeIcon icon={faSignOutAlt} />
                                        </div>
                                        <div className="p-11 pt-1 font-weight-lighter">
                                            <div>{logoutLabel}</div>
                                        </div>
                                    </div>
                                </Link>
                            </DropdownItem>
                        </DropdownMenu>
                    </ButtonWrapper>
                </Dropdown>
            </>
        );
    };

    const { isAuthenticated, userName, fullName } = userInfo;

    if (!isAuthenticated) {
        return anonymousView(ApplicationPaths.Login);
    } else {
        const logoutPath = { pathname: ApplicationPaths.LogOut, state: { local: true } };
        return authenticatedView(userName, fullName, ApplicationPaths.Profile, logoutPath);
    }
};

const ButtonWrapper = styled.div`
    position: relative;
`;

const Icon = styled(HeaderIconButton)<{ isLoading?: boolean }>`
    &.loading {
        animation: pulse 1s infinite;
    }

    @keyframes pulse {
        0% {
            transform: scale(0.95);
        }
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(0.95);
        }
    }
`;

export default LoginMenu;
