"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopTypeToJSON = exports.ShopTypeFromJSONTyped = exports.ShopTypeFromJSON = exports.ShopType = void 0;
/**
 *
 * @export
 */
exports.ShopType = {
    Individual: 0,
    Units: 1
};
function ShopTypeFromJSON(json) {
    return ShopTypeFromJSONTyped(json, false);
}
exports.ShopTypeFromJSON = ShopTypeFromJSON;
function ShopTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ShopTypeFromJSONTyped = ShopTypeFromJSONTyped;
function ShopTypeToJSON(value) {
    return value;
}
exports.ShopTypeToJSON = ShopTypeToJSON;
