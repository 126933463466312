"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopToJSON = exports.ShopFromJSONTyped = exports.ShopFromJSON = exports.instanceOfShop = void 0;
var runtime_1 = require("../runtime");
var FilterInputItem_1 = require("./FilterInputItem");
var ShopVatMode_1 = require("./ShopVatMode");
var Unit_1 = require("./Unit");
/**
 * Check if a given object implements the Shop interface.
 */
function instanceOfShop(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfShop = instanceOfShop;
function ShopFromJSON(json) {
    return ShopFromJSONTyped(json, false);
}
exports.ShopFromJSON = ShopFromJSON;
function ShopFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'vatMode': !(0, runtime_1.exists)(json, 'vatMode') ? undefined : (0, ShopVatMode_1.ShopVatModeFromJSON)(json['vatMode']),
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'uri': !(0, runtime_1.exists)(json, 'uri') ? undefined : json['uri'],
        'bodyText': !(0, runtime_1.exists)(json, 'bodyText') ? undefined : json['bodyText'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'agreement': !(0, runtime_1.exists)(json, 'agreement') ? undefined : json['agreement'],
        'contactEmail': !(0, runtime_1.exists)(json, 'contactEmail') ? undefined : json['contactEmail'],
        'contactPhone': !(0, runtime_1.exists)(json, 'contactPhone') ? undefined : json['contactPhone'],
        'contactName': !(0, runtime_1.exists)(json, 'contactName') ? undefined : json['contactName'],
        'company': !(0, runtime_1.exists)(json, 'company') ? undefined : json['company'],
        'companyText': !(0, runtime_1.exists)(json, 'companyText') ? undefined : json['companyText'],
        'vatExcluded': !(0, runtime_1.exists)(json, 'vatExcluded') ? undefined : json['vatExcluded'],
        'displayMarketingAgreement': !(0, runtime_1.exists)(json, 'displayMarketingAgreement') ? undefined : json['displayMarketingAgreement'],
        'marketingAgreement': !(0, runtime_1.exists)(json, 'marketingAgreement') ? undefined : json['marketingAgreement'],
        'privacyPolicyUrl': !(0, runtime_1.exists)(json, 'privacyPolicyUrl') ? undefined : json['privacyPolicyUrl'],
        'legalNoticeUrl': !(0, runtime_1.exists)(json, 'legalNoticeUrl') ? undefined : json['legalNoticeUrl'],
        'isB2BShop': !(0, runtime_1.exists)(json, 'isB2BShop') ? undefined : json['isB2BShop'],
        'assortmentFilters': !(0, runtime_1.exists)(json, 'assortmentFilters') ? undefined : (json['assortmentFilters'] === null ? null : json['assortmentFilters'].map(FilterInputItem_1.FilterInputItemFromJSON)),
        'files': !(0, runtime_1.exists)(json, 'files') ? undefined : json['files'],
        'selectedUnit': !(0, runtime_1.exists)(json, 'selectedUnit') ? undefined : (0, Unit_1.UnitFromJSON)(json['selectedUnit']),
    };
}
exports.ShopFromJSONTyped = ShopFromJSONTyped;
function ShopToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'vatMode': (0, ShopVatMode_1.ShopVatModeToJSON)(value.vatMode),
        'name': value.name,
        'uri': value.uri,
        'bodyText': value.bodyText,
        'description': value.description,
        'agreement': value.agreement,
        'contactEmail': value.contactEmail,
        'contactPhone': value.contactPhone,
        'contactName': value.contactName,
        'company': value.company,
        'companyText': value.companyText,
        'vatExcluded': value.vatExcluded,
        'displayMarketingAgreement': value.displayMarketingAgreement,
        'marketingAgreement': value.marketingAgreement,
        'isB2BShop': value.isB2BShop,
        'assortmentFilters': value.assortmentFilters === undefined ? undefined : (value.assortmentFilters === null ? null : value.assortmentFilters.map(FilterInputItem_1.FilterInputItemToJSON)),
        'files': value.files,
        'selectedUnit': (0, Unit_1.UnitToJSON)(value.selectedUnit),
    };
}
exports.ShopToJSON = ShopToJSON;
