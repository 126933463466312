"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitToJSON = exports.InitFromJSONTyped = exports.InitFromJSON = exports.instanceOfInit = void 0;
var runtime_1 = require("../runtime");
var ShopSecurityType_1 = require("./ShopSecurityType");
var ShopTheme_1 = require("./ShopTheme");
var ShopType_1 = require("./ShopType");
/**
 * Check if a given object implements the Init interface.
 */
function instanceOfInit(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfInit = instanceOfInit;
function InitFromJSON(json) {
    return InitFromJSONTyped(json, false);
}
exports.InitFromJSON = InitFromJSON;
function InitFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : (0, ShopType_1.ShopTypeFromJSON)(json['type']),
        '_exists': !(0, runtime_1.exists)(json, 'exists') ? undefined : json['exists'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'theme': !(0, runtime_1.exists)(json, 'theme') ? undefined : (0, ShopTheme_1.ShopThemeFromJSON)(json['theme']),
        'securityType': !(0, runtime_1.exists)(json, 'securityType') ? undefined : (0, ShopSecurityType_1.ShopSecurityTypeFromJSON)(json['securityType']),
        'language': !(0, runtime_1.exists)(json, 'language') ? undefined : json['language'],
        'shippingCountries': !(0, runtime_1.exists)(json, 'shippingCountries') ? undefined : json['shippingCountries'],
        'backgroundImage': !(0, runtime_1.exists)(json, 'backgroundImage') ? undefined : json['backgroundImage'],
        'logoType': !(0, runtime_1.exists)(json, 'logoType') ? undefined : json['logoType'],
    };
}
exports.InitFromJSONTyped = InitFromJSONTyped;
function InitToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, ShopType_1.ShopTypeToJSON)(value.type),
        'theme': (0, ShopTheme_1.ShopThemeToJSON)(value.theme),
        'securityType': (0, ShopSecurityType_1.ShopSecurityTypeToJSON)(value.securityType),
    };
}
exports.InitToJSON = InitToJSON;
