"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopCustomerOrderOverviewDataToJSON = exports.ShopCustomerOrderOverviewDataFromJSONTyped = exports.ShopCustomerOrderOverviewDataFromJSON = exports.instanceOfShopCustomerOrderOverviewData = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the ShopCustomerOrderOverviewData interface.
 */
function instanceOfShopCustomerOrderOverviewData(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfShopCustomerOrderOverviewData = instanceOfShopCustomerOrderOverviewData;
function ShopCustomerOrderOverviewDataFromJSON(json) {
    return ShopCustomerOrderOverviewDataFromJSONTyped(json, false);
}
exports.ShopCustomerOrderOverviewDataFromJSON = ShopCustomerOrderOverviewDataFromJSON;
function ShopCustomerOrderOverviewDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'orderNumber': !(0, runtime_1.exists)(json, 'orderNumber') ? undefined : json['orderNumber'],
        'created': !(0, runtime_1.exists)(json, 'created') ? undefined : (new Date(json['created'])),
        'currency': !(0, runtime_1.exists)(json, 'currency') ? undefined : json['currency'],
        'totalIncVat': !(0, runtime_1.exists)(json, 'totalIncVat') ? undefined : json['totalIncVat'],
        'total': !(0, runtime_1.exists)(json, 'total') ? undefined : json['total'],
        'totalExVat': !(0, runtime_1.exists)(json, 'totalExVat') ? undefined : json['totalExVat'],
        'quantity': !(0, runtime_1.exists)(json, 'quantity') ? undefined : json['quantity'],
        'deliveryAdressId': !(0, runtime_1.exists)(json, 'deliveryAdressId') ? undefined : json['deliveryAdressId'],
        'deliveryAdressEmail': !(0, runtime_1.exists)(json, 'deliveryAdressEmail') ? undefined : json['deliveryAdressEmail'],
        'deliveryAdressPhone': !(0, runtime_1.exists)(json, 'deliveryAdressPhone') ? undefined : json['deliveryAdressPhone'],
        'deliveryAdressName': !(0, runtime_1.exists)(json, 'deliveryAdressName') ? undefined : json['deliveryAdressName'],
        'deliveryAdressLine1': !(0, runtime_1.exists)(json, 'deliveryAdressLine1') ? undefined : json['deliveryAdressLine1'],
        'deliveryAdressLine2': !(0, runtime_1.exists)(json, 'deliveryAdressLine2') ? undefined : json['deliveryAdressLine2'],
        'deliveryAdressPostalCode': !(0, runtime_1.exists)(json, 'deliveryAdressPostalCode') ? undefined : json['deliveryAdressPostalCode'],
        'deliveryAdressCountry': !(0, runtime_1.exists)(json, 'deliveryAdressCountry') ? undefined : json['deliveryAdressCountry'],
        'deliveryAdressCity': !(0, runtime_1.exists)(json, 'deliveryAdressCity') ? undefined : json['deliveryAdressCity'],
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
    };
}
exports.ShopCustomerOrderOverviewDataFromJSONTyped = ShopCustomerOrderOverviewDataFromJSONTyped;
function ShopCustomerOrderOverviewDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'orderNumber': value.orderNumber,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'currency': value.currency,
        'totalIncVat': value.totalIncVat,
        'total': value.total,
        'totalExVat': value.totalExVat,
        'quantity': value.quantity,
        'deliveryAdressId': value.deliveryAdressId,
        'deliveryAdressEmail': value.deliveryAdressEmail,
        'deliveryAdressPhone': value.deliveryAdressPhone,
        'deliveryAdressName': value.deliveryAdressName,
        'deliveryAdressLine1': value.deliveryAdressLine1,
        'deliveryAdressLine2': value.deliveryAdressLine2,
        'deliveryAdressPostalCode': value.deliveryAdressPostalCode,
        'deliveryAdressCountry': value.deliveryAdressCountry,
        'deliveryAdressCity': value.deliveryAdressCity,
        'id': value.id,
    };
}
exports.ShopCustomerOrderOverviewDataToJSON = ShopCustomerOrderOverviewDataToJSON;
