"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopUserTypeToJSON = exports.ShopUserTypeFromJSONTyped = exports.ShopUserTypeFromJSON = exports.ShopUserType = void 0;
/**
 *
 * @export
 */
exports.ShopUserType = {
    User: 0,
    ShopManager: 1
};
function ShopUserTypeFromJSON(json) {
    return ShopUserTypeFromJSONTyped(json, false);
}
exports.ShopUserTypeFromJSON = ShopUserTypeFromJSON;
function ShopUserTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ShopUserTypeFromJSONTyped = ShopUserTypeFromJSONTyped;
function ShopUserTypeToJSON(value) {
    return value;
}
exports.ShopUserTypeToJSON = ShopUserTypeToJSON;
