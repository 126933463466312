import { CustomerApi, Init, Shop, ShopApi, Unit } from 'microshop-api';
import { config } from './api';

const shopApi = new ShopApi(config);

const shop = {
    initiate: (): Promise<Init> => shopApi.shopInfo(),
    load: (): Promise<Shop> => shopApi.shopGet(),
    verify: (): Promise<boolean> => shopApi.shopVerify(),
};

export { shop };
