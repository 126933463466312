import Layout from 'components/ui/Layout';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { ShopSecurityType } from 'microshop-api';
import ShopError from 'pages/ShopError';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getAllCategories, loadAssortmentLocation } from 'store/reducers/assortmentSlice';
import { loadCart } from 'store/reducers/cartSlice';
import { loadShop } from 'store/reducers/shopSlice';
import { loadCustomer } from 'store/reducers/userSlice';
import ShopLoad from './ShopLoad';
import ShopRoutes from './ShopRoutes';
import ShopSecurity from './ShopSecurity';

const Shop = () => {
    const { t, ready, i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { hostName, initiating, error, info, verified, shop, loading } = useAppSelector(({ shop }) => shop);
    const { cart, cartLoading } = useAppSelector(({ cart }) => ({ cart: cart.cart, cartLoading: cart.loading }));
    // Once verified - get shop data
    useEffect(() => {
        if (verified && !loading && !shop) {
            dispatch(loadShop());
            dispatch(loadCart());
            dispatch(getAllCategories());
        }
    }, [verified, loading, shop, dispatch]);

    useEffect(() => {
        if (ready && info && (info as any).language) {
            if ((info as any).language !== i18n.language) i18n.changeLanguage((info as any).language);
        }
    }, [info, ready, t, i18n]);

    useEffect(() => {
        dispatch(loadAssortmentLocation(location.pathname));
    }, [dispatch, location]);

    useEffect(() => {
        if (shop?.isB2BShop || info?.type == 1) {
            dispatch(loadCustomer());
        }
    }, [dispatch, shop?.isB2BShop]);

    const render = (() => {
        if (error) return <ShopError errorKey={error} />;

        // Return loading until info is loaded.
        if (!hostName || !info || initiating) return <ShopLoad />;

        // If authentication is needed
        if (!verified && info.securityType !== ShopSecurityType.None) {
            return <ShopSecurity securityType={info.securityType} />;
        }

        // Return loading until shop is loaded
        if (shop)
            return (
                <Layout>
                    <ShopRoutes />
                </Layout>
            );
        return <ShopLoad />;
    })();

    useEffect(() => {
        if (!cart && !cartLoading) {
            dispatch(loadCart());
        }
    }, [dispatch, cart, cartLoading]);

    return render;
};

export default Shop;
